import $ from 'jquery';
import 'bootstrap';
import 'parsleyjs';
import  Cookies from 'js-cookie';
import "magnific-popup";
import 'jquery-match-height'
import AOS from 'aos';
import Swiper from 'swiper';
import 'bootstrap-select';
import 'simplebar';

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );
var imagesloaded = require('imagesloaded');
jQueryBridget( 'imagesLoaded', imagesloaded, $ );

window.$ = window.jQuery = $;

let Page = Page || {};
(function($) {

    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
    var $window = $(window),
        $html = $('html'),
        $body = $('body');
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    $(document).ready( Page.documentOnReady.init);
    $window.on('load', Page.documentOnLoad.init);
    $window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.matchHeight();
            Page.initialize.aos();
            Page.initialize.cookie();
            Page.initialize.header();
            Page.initialize.innerLinks();
            Page.initialize.formValidation();
            Page.initialize.magnificPopup();
            Page.initialize.swiper();
            Page.initialize.map();
            Page.initialize.shopsModal();
            Page.initialize.gridMansory();
        },
        
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            $body.removeClass("loading");
            $body.addClass("loaded");
        },
        /* ---------------------------------------------
            Grid mansory
        --------------------------------------------- */
        gridMansory: function() {
            if (  $('.grid-mansory').length > 0){
                var $grid = $('.grid-mansory').isotope({
                    itemSelector: 'li',
                    percentPosition: false,
                });


                // layout Masonry after each image loads
                $('.grid-mansory').imagesLoaded( function() {
                    $grid.isotope('layout');
                });

                $('.filter-mansory').click(function(){
                    $('.filter-mansory').removeClass('active');
                    $(this).addClass('active');

                    var selector = $(this).attr('data-filter');
                    $grid.isotope({
                        filter: selector,
                        animationOptions: {
                            duration: 750,
                            easing: 'linear',
                            queue: false
                        }
                    });

                    return false;
                });
            }
        },
        
        /* ---------------------------------------------
            swiper
        --------------------------------------------- */
        swiper: function(){
            // swiper main
            if ( $('#swiper-main').length > 0 ) {
                let swiperMainLength = $('#swiper-main .swiper-slide').length;
                let swiperMain = new Swiper('#swiper-main', {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    simulateTouch: false,
                    touchRatio: 0,
                    loop: swiperMainLength > 1,
                    allowTouchMove: false,
                    speed: 1200,
                    navigation: {
                        nextEl: '#swiper-main-next',
                        prevEl: '#swiper-main-prev',
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                });

                swiperMain.on('transitionEnd', function () {
                    let currentVideo = $('#swiper-main .swiper-slide-active video');
                    if ( currentVideo.length > 0 ) {
                        let videoElem = $('#swiper-main .swiper-slide-active video')[0];
                        videoElem.currentTime = 0;
                        videoElem.play();
                    } 

                    let slidesVideos = $('#swiper-main .swiper-slide:not(.swiper-slide-active)');
                    slidesVideos.each(function () {
                        let videoElem = $(this).find('video')[0];
                        if ( videoElem ) {
                            videoElem.currentTime = 0;
                            videoElem.pause();
                        }
                    });
                });
            }

            // swiper map
            if ( $('#swiper-map').length > 0 ) {
                let swiperMapLength = $('#swiper-map .swiper-slide').length;
                let swiperMap = new Swiper('#swiper-map', {
                    slidesPerView: 'auto',
                    loopedSlides: 0,
                    spaceBetween: 0,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    speed: 1200,
                    navigation: {
                        nextEl: '#swiper-map-next',
                        prevEl: '#swiper-map-prev',
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        640: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                          loop: false,
                        },
                    }
                });
            }

            // swiper gallery
            if ( $('.swiper-gallery').length > 0 ) {
                $('.swiper-gallery').each(function(){
                    let swiperGalleryLength = $(this).find('.swiper-slide').length;
                    let swiperGallery = new Swiper(this, {
                        slidesPerView: 3,
                        spaceBetween: 15,
                        simulateTouch: false,
                        touchRatio: 0,
                        loop: swiperGalleryLength > 3,
                        allowTouchMove: false,
                        speed: 1200,
                        navigation: {
                            nextEl: $(this).closest('.section-swiper').find('.swiper-gallery-next')[0],
                            prevEl: $(this).closest('.section-swiper').find('.swiper-gallery-prev')[0],
                        },
                        breakpoints: {
                            // when window width is <= 640px
                            640: {
                              slidesPerView: 2,
                              spaceBetween: 10,
                              loop: swiperGalleryLength > 2,
                            },
                        }
                    });
                });
            }

            // swiper blog
            if ( $('.swiper-blog').length > 0 ) {
                $('.swiper-blog').each(function(){
                    let swiperBlogLength = $(this).find('.swiper-slide').length;
                    let swiperBlog = new Swiper(this, {
                        slidesPerView: 4,
                        spaceBetween: 15,
                        simulateTouch: false,
                        touchRatio: 0,
                        loop: swiperBlogLength > 4,
                        allowTouchMove: false,
                        speed: 1200,
                        navigation: {
                            nextEl: $(this).closest('.section-swiper').find('.swiper-blog-next')[0],
                            prevEl: $(this).closest('.section-swiper').find('.swiper-blog-prev')[0],
                        },
                        breakpoints: {
                            // when window width is <= 640px
                            640: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                            loop: swiperBlogLength > 2,
                            },
                        }
                    });
                });
            }

            // swiper news
            if ( $('#swiper-news').length > 0 ) {
                let swiperNewsLength = $('#swiper-news .swiper-slide').length;
                let swiperNews = new Swiper('#swiper-news', {
                    slidesPerView: 3,
                    spaceBetween: 15,
                    simulateTouch: false,
                    touchRatio: 0,
                    loop: swiperNewsLength > 2,
                    allowTouchMove: false,
                    speed: 1200,
                    navigation: {
                        nextEl: '#swiper-news-next',
                        prevEl: '#swiper-news-prev',
                    },
                    breakpoints: {
                        // when window width is <= 640px
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                          loop: swiperNewsLength > 1,
                        },
                    }
                });
            }
        },

        /* ---------------------------------------------
            matchHeight
        --------------------------------------------- */
        matchHeight: function() {
            if ($('.cards-list').length > 0) {
                $('.cards-list .card-body').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }

            if ($('.shop-items-list').length > 0) {
                $('.shop-items-list .shop-item-title').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.shop-items-list .shop-item-logo').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }
        },
        
        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            AOS.init({
                // Global settings
                //disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                //startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 400, // values from 0 to 3000, with step 50ms
                duration: 450, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });

            window.addEventListener('load', AOS.refresh);
        },

        /* ---------------------------------------------
            Form validation
        --------------------------------------------- */
        formValidation: function () {
            //Global validation
            window.Parsley.on('field:error', function() {
                this.$element.addClass('form-control-danger');
            });
                
            window.Parsley.on('field:success', function() {
                this.$element.removeClass('form-control-danger');
                this.$element.addClass('form-control-success');
            });

            // Form gruop heleper classes
            $('form[novalidate]').parsley({
                successClass: "has-success",
                errorClass: "has-danger",
                classHandler: function(el) {
                    return el.$element.closest(".form-group");
                },
                errorsWrapper: '<ul class="parslay-danger"></ul>',
                errorElem: '<li></li>'
            });

            $('form[novalidate]').on('submit', function( evt){
                let form = $(this);
                form.parsley().validate();
                
                if (form.parsley().isValid()){
                    if ( form.attr('data-prevent') === 'true' ){
                        evt.preventDefault();
                    }

                    if ( form.attr('data-modal-show')){
                        var modal = form.attr('data-modal-show');
                        form[0].reset();
                        $(modal + "").modal('show')
                    }
                }
            });
        },

        /* ---------------------------------------------
            magnificPopup
        --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };

            $('.popup-video').magnificPopup({
                disableOn: 700,
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false
            });

            $('[data-lightbox="inline"]').magnificPopup({
                type:'inline',
            });
        },

        /* ---------------------------------------------
            map
        --------------------------------------------- */
        map: function(){
            
            if($('#section-pasaz').length){

                let mapSlides = $('#section-pasaz .slide-map');
                let mapBox = $('#map-section-box');
                let mapBoxContent = $('#box-body-content');
                let mapBoxImage = $('#map-section-box .box-image');
                let mapShopList = $('#map-shop-list');
                let mapShopListButton = $('#map-shop-button');
                let boxClose = $('.box-close');

                mapSlides.on('click', function (e) {
                    e.preventDefault();
                    
                    if ( $(window).width() < 719 ){
                        let modalSp = $('#modalSp');
                        let modalSpContent = $('#modal-Sp-content');
                        let modalSpSopsContent = $('#modal-SpShops-content');
                        let content = $(this).find('.slide-map-content').html();
                        let contentShops = $(this).find('.slide-map-shop-list').html();
                        modalSpContent.html(content);
                        modalSpSopsContent.html(contentShops)
                        modalSp.modal('show');

                    } else {
                        mapSlides.removeClass('active');
                        mapBox.removeClass('active');
                        mapShopList.removeClass('active');
                        mapShopListButton.removeClass('active');
                        $(this).addClass('active');
                        let content = $(this).find('.slide-map-content').html();
                        mapBoxContent.html(content);

                        let modalSpImage = mapSlides.find('.slide-map-image img').attr('src');
                        mapBoxImage.css("background-image", "url("+modalSpImage+")");
                        setTimeout(function(){
                            mapBox.addClass('active');
                        }, 450);

                        let contentShopList = $(this).find('.slide-map-shop-list').html();
                        mapShopList.find('.simplebar-inner').html(contentShopList);
                        
                        setTimeout(function(){
                            mapShopList.addClass('active');
                        }, 650);
                    }
                });

                $('#swiper-map-next, #swiper-map-prev').on('click', function(){
                    mapSlides.removeClass('active');
                    mapBox.removeClass('active');
                    mapShopList.removeClass('active');
                    mapShopListButton.removeClass('active');
                });
                
                mapShopList.on('click', 'li', function(){
                    let content = $(this).find('.shop-content').html();
                    let contentImg = $(this).find('.shop-content .shop-image img').attr('src');
                    mapShopList.find('li').removeClass('active');
                    $(this).addClass('active');
                    mapBoxContent.html(content);

                    if (contentImg) {
                        mapBoxImage.css("background-image", "url("+contentImg+")");
                    }
                });

                boxClose.on('click', function(){
                    mapSlides.removeClass('active');
                    mapBox.removeClass('active');
                    mapShopList.removeClass('active');
                })
            }
        },

        /* ---------------------------------------------
            shopsModal
        --------------------------------------------- */
        shopsModal: function(){
            if($('.shop-items-list').length){
                $('.shop-items-list li').on('click', function (e) {
                    e.preventDefault();
                    let shopsModal = $('#modalShops');
                    let shopsModalContent = $('#modalShops-content');
                    let shopsModalImage = $('#modalShops-image');

                    let modalImage = $(this).find('.shop-item-image').css('background-image');
                    let content = $(this).find('.shop-item').html();
                    
                    shopsModalContent.html(content);
                    shopsModalImage.css("background-image", modalImage);
                    shopsModal.modal('show');
                });
            }
        },

        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    console.log('click')
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 600;
                    var offset = $(this).attr('data-offset') || 0;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            let scroll_amount = 200;
            
            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                $('body').toggleClass("menu-is-active");
            });

            // scroll event
            if ( $window.scrollTop() > scroll_amount ){
                $body.addClass("body-scroll");
            }
            $window.scroll(function() {
                if ( $(this).scrollTop() > scroll_amount ){
                    $body.addClass("page-scroll");
                } else{
                    $body.removeClass("page-scroll");
                }
            });
        },
    };
})(jQuery);





